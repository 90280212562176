import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconCaretRight({
  className = "hbs-icon",
  ...props
}: IconProps) {
  return (
    <svg
      viewBox="0 0 8 13"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5.87638 6.58349L0.646446 1.35355L1.35355 0.646446L7.29059 6.58349L1.35355 12.5205L0.646446 11.8134L5.87638 6.58349Z"
      />
    </svg>
  );
}
