import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconArrowOpen({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 8 13"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M1 0.5L6.58349 6.08349L1 11.667"
        stroke="currentColor"
        strokeOpacity="0.65"
      />
    </svg>
  );
}
