import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconMenu({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 14 12"
      fill="none"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <rect fill="currentColor" width="20" height="1.5" x="0" y="1" />
      <rect fill="currentColor" width="20" height="1.5" x="0" y="5" />
      <rect fill="currentColor" width="20" height="1.5" x="0" y="9" />
    </svg>
  );
}
