import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconApple({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        d="M23.4598 19.8555C23.1428 20.5879 22.7675 21.2622 22.3326 21.882C21.7399 22.7271 21.2546 23.312 20.8806 23.6369C20.3009 24.17 19.6797 24.4431 19.0145 24.4586C18.537 24.4586 17.9611 24.3227 17.2908 24.0471C16.6182 23.7727 16.0001 23.6369 15.435 23.6369C14.8423 23.6369 14.2066 23.7727 13.5267 24.0471C12.8457 24.3227 12.2972 24.4664 11.8777 24.4806C11.2399 24.5078 10.6041 24.227 9.96942 23.6369C9.56436 23.2836 9.05772 22.6779 8.45077 21.8199C7.79957 20.9037 7.2642 19.8412 6.84477 18.6299C6.39559 17.3216 6.17041 16.0546 6.17041 14.8281C6.17041 13.423 6.47401 12.2112 7.08212 11.1957C7.56003 10.3801 8.19583 9.73662 8.99159 9.26427C9.78734 8.79191 10.6472 8.55121 11.5731 8.53581C12.0797 8.53581 12.7441 8.69252 13.5698 9.00052C14.3931 9.30956 14.9218 9.46628 15.1535 9.46628C15.3268 9.46628 15.9141 9.28303 16.9096 8.9177C17.8511 8.5789 18.6457 8.43862 19.2966 8.49388C21.0605 8.63623 22.3857 9.33156 23.267 10.5843C21.6895 11.5401 20.9091 12.8789 20.9246 14.5963C20.9389 15.934 21.4242 17.0472 22.3779 17.9311C22.8102 18.3413 23.2929 18.6584 23.8299 18.8836C23.7135 19.2213 23.5905 19.5449 23.4598 19.8555V19.8555ZM19.4144 3.93651C19.4144 4.98502 19.0314 5.96401 18.2678 6.87015C17.3464 7.94737 16.2319 8.56984 15.0233 8.47162C15.0079 8.34583 14.999 8.21344 14.999 8.07432C14.999 7.06776 15.4372 5.99054 16.2154 5.10976C16.6038 4.66381 17.0979 4.293 17.6971 3.99721C18.295 3.70582 18.8605 3.54468 19.3924 3.51709C19.4079 3.65726 19.4144 3.79743 19.4144 3.9365V3.93651Z"
      />
    </svg>
  );
}
