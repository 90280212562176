import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconX({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.927 13.8052L21.5111 7H20.1879L15.3392 12.9088L11.4666 7H7L12.8562 15.9352L7 23.0714H8.32332L13.4436 16.8315L17.5334 23.0714H22L15.9267 13.8052H15.927ZM14.1146 16.0139L13.5212 15.1242L8.80014 8.04439H10.8327L14.6426 13.758L15.236 14.6477L20.1885 22.0745H18.156L14.1146 16.0142V16.0139Z"
      />
    </svg>
  );
}
