import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconArrow({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 11 11"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        className="arrow"
        fill="currentColor"
        d="M11 5.5L5.44416 11L4.33299 9.9L8.04807 6.22254L-2.71996e-07 6.22254L-2.03997e-07 4.6669L7.9365 4.66691L4.33299 1.1L5.44416 -2.42853e-07L11 5.5Z"
      />
    </svg>
  );
}
