import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconExternal({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 27 27"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M22.389 4.61633L22.3495 12.434L20.786 12.4419L20.8126 7.21458L13.3217 14.8272L12.2217 13.7272L19.6337 6.19347L14.5635 6.21935L14.5714 4.65582L22.389 4.61633Z"
        className="external-arrow"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9C0 7.89543 0.89543 7 2 7H11.5C12.0523 7 12.5 7.44772 12.5 8C12.5 8.55228 12.0523 9 11.5 9H2V25H17.5V15.5C17.5 14.9477 17.9477 14.5 18.5 14.5C19.0523 14.5 19.5 14.9477 19.5 15.5V25C19.5 26.1046 18.6046 27 17.5 27H2C0.89543 27 0 26.1046 0 25V9Z"
        fill="currentColor"
      />
    </svg>
  );
}
