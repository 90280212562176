import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconDownload({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 11 11"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        className="download-arrow"
        d="M4.77502 0V5.95923L2.02527 3.07853L1 4.15262L5.5 8.86691L10 4.15262L8.97473 3.07853L6.22498 5.95923V0H4.77502Z"
        fill="currentColor"
      />
      <path d="M1 9.42857V11H10V9.42857H1Z" fill="currentColor" />
    </svg>
  );
}
