import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconCompare({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 28 23"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        className="left-arrow"
        d="M8.20938 22.1877L6.76562 20.7439L10.925 16.5846H0.0625V14.5221H10.925L6.76562 10.3627L8.20938 8.91895L14.8438 15.5533L8.20938 22.1877Z"
        fill="currentColor"
      />
      <path
        className="right-arrow"
        d="M12.7812 6.82188L19.4156 13.4563L20.8594 12.0125L16.7 7.85313H27.5625V5.79063H16.7L20.8594 1.63125L19.4156 0.1875L12.7812 6.82188Z"
        fill="currentColor"
      />
    </svg>
  );
}
