import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconSearch({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 17 15"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        d="M1.87046 7.33288C1.87046 4.31603 4.31609 1.8704 7.33294 1.8704C10.3498 1.8704 12.7954 4.31603 12.7954 7.33288C12.7954 10.3497 10.3498 12.7954 7.33294 12.7954C4.31609 12.7954 1.87046 10.3497 1.87046 7.33288ZM7.33294 0.270229C3.43235 0.270229 0.27029 3.43229 0.27029 7.33288C0.27029 11.2335 3.43235 14.3955 7.33294 14.3955C9.28324 14.3955 11.0489 13.605 12.327 12.3269L14.9297 14.9296L16.0612 13.7981L13.3293 11.0663C14.0051 9.98312 14.3956 8.7036 14.3956 7.33288C14.3956 3.43229 11.2335 0.270229 7.33294 0.270229Z"
      />
    </svg>
  );
}
