import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconSlashCircle({
  className = "hbs-icon",
  ...props
}: IconProps) {
  return (
    <svg viewBox="0 0 16 16" className={className} {...props}>
      <path
        fill="currentColor"
        d="M6.38 11.02 10.9 6.5 9.78 5.38 5.26 9.9l1.12 1.12ZM8 16a7.79 7.79 0 0 1-3.12-.63 8.079 8.079 0 0 1-2.54-1.71 8.08 8.08 0 0 1-1.71-2.54A7.79 7.79 0 0 1 0 8c0-1.107.21-2.147.63-3.12.42-.973.99-1.82 1.71-2.54A8.079 8.079 0 0 1 4.88.63 7.79 7.79 0 0 1 8 0a7.79 7.79 0 0 1 3.12.63c.973.42 1.82.99 2.54 1.71s1.29 1.567 1.71 2.54c.42.973.63 2.013.63 3.12a7.79 7.79 0 0 1-.63 3.12 8.08 8.08 0 0 1-1.71 2.54 8.08 8.08 0 0 1-2.54 1.71A7.79 7.79 0 0 1 8 16Zm0-1.6c1.787 0 3.3-.62 4.54-1.86C13.78 11.3 14.4 9.787 14.4 8s-.62-3.3-1.86-4.54C11.3 2.22 9.787 1.6 8 1.6s-3.3.62-4.54 1.86C2.22 4.7 1.6 6.213 1.6 8s.62 3.3 1.86 4.54C4.7 13.78 6.213 14.4 8 14.4Z"
      />
    </svg>
  );
}
