import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconCaretDown({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 13 7"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M6.91696 5.37617L12.1469 0.14624L12.854 0.853344L6.91696 6.79038L0.97995 0.853344L1.68705 0.14624L6.91696 5.37617Z"
        fill="currentColor"
      />
    </svg>
  );
}
