import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconFilter({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg viewBox="0 0 19 13" className={className} {...props}>
      <path
        d="M7.25 12.8906H11.25V10.8906H7.25V12.8906ZM0.25 0.890625V2.89062H18.25V0.890625H0.25ZM3.25 7.89062H15.25V5.89062H3.25V7.89062Z"
        fill="currentColor"
      />
    </svg>
  );
}
