import type { IconProps as IconComponentProps } from "design-system/types/types";
import { FC } from "react";
import { IconApple } from "./icon-apple";
import { IconArrow } from "./icon-arrow";
import { IconArrowLeft } from "./icon-arrow-left";
import { IconArrowOpen } from "./icon-arrow-open";
import { IconBell } from "./icon-bell";
import { IconCaret } from "./icon-caret";
import { IconCaretDown } from "./icon-caret-down";
import { IconCaretHeavy } from "./icon-caret-heavy";
import { IconCaretRight } from "./icon-caret-right";
import { IconCheck } from "./icon-check";
import { IconCheckCircle } from "./icon-check-circle";
import { IconCheckLarge } from "./icon-check-large";
import { IconClose } from "./icon-close";
import { IconCloseCircle } from "./icon-close-circle";
import { IconCompare } from "./icon-compare";
import { IconComment } from "./icon-comment";
import { IconDownload } from "./icon-download";
import { IconEllipsis } from "./icon-ellipsis";
import { IconExternal } from "./icon-external";
import { IconFacebook } from "./icon-facebook";
import { IconFilter } from "./icon-filter";
import { IconInstagram } from "./icon-instagram";
import { IconLink } from "./icon-link";
import { IconLinkedIn } from "./icon-linkedin";
import { IconMail } from "./icon-mail";
import { IconMenu } from "./icon-menu";
import { IconMinus } from "./icon-minus";
import { IconNavArrow } from "./icon-nav-arrow";
import { IconPause } from "./icon-pause";
import { IconPlay } from "./icon-play";
import { IconPlus } from "./icon-plus";
import { IconPrint } from "./icon-print";
import { IconQuestionMark } from "./icon-question-mark";
import { IconQuickLink } from "./icon-quick-link";
import { IconQuickLinkCircle } from "./icon-quick-link-circle";
import { IconSearch } from "./icon-search";
import { IconSlashCircle } from "./icon-slash-circle";
import { IconTikTok } from "./icon-tiktok";
import { IconYoutube } from "./icon-youtube";
import { IconX } from "./icon-x";
import { IconCalendar } from "./icon-calendar";

export const Icons = {
  apple: IconApple,
  applePodcasts: IconApple,
  arrow: IconArrow,
  arrowLeft: IconArrowLeft,
  arrowOpen: IconArrowOpen,
  bell: IconBell,
  calendar: IconCalendar,
  caret: IconCaret,
  caretDown: IconCaretDown,
  caretHeavy: IconCaretHeavy,
  caretRight: IconCaretRight,
  check: IconCheck,
  checkCircle: IconCheckCircle,
  checkLarge: IconCheckLarge,
  close: IconClose,
  closeCircle: IconCloseCircle,
  comment: IconComment,
  compare: IconCompare,
  download: IconDownload,
  ellipsis: IconEllipsis,
  external: IconExternal,
  facebook: IconFacebook,
  filter: IconFilter,
  instagram: IconInstagram,
  itunes: IconApple,
  link: IconLink,
  linkedin: IconLinkedIn,
  mail: IconMail,
  menu: IconMenu,
  minus: IconMinus,
  navArrow: IconNavArrow,
  pause: IconPause,
  play: IconPlay,
  plus: IconPlus,
  print: IconPrint,
  questionMark: IconQuestionMark,
  quicklink: IconQuickLink,
  quicklinkCircle: IconQuickLinkCircle,
  search: IconSearch,
  slashCircle: IconSlashCircle,
  tiktok: IconTikTok,
  youtube: IconYoutube,
  x: IconX,
} satisfies Record<string, FC<IconComponentProps>>;
