import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconArrowLeft({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 11 11"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        d="M0 5.5L5.55584 11L6.66701 9.9L2.95193 6.22254H11L11 4.6669L3.0635 4.66691L6.66701 1.1L5.55584 0L0 5.5Z"
      />
    </svg>
  );
}
