import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconCheck({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 9 7"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M8.83004 2.47274L3.58227 6.97083L0.854736 3.78901L2.37319 2.48736L3.79913 4.15079L7.52846 0.954224L8.83004 2.47274Z"
        fill="currentColor"
      />
    </svg>
  );
}
