import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconQuickLinkCircle({
  className = "hbs-icon",
  ...props
}: IconProps) {
  return (
    <svg
      viewBox="0 0 22 22"
      className={className}
      fill="none"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke="currentColor"
      />
      <path
        d="M9.7037 8.50717C8.5254 9.72629 8.78253 11.7495 10.1323 12.6457C10.1767 12.6753 10.2359 12.6694 10.2741 12.6321C10.5582 12.3544 10.7986 12.0855 11.0091 11.7435C11.0413 11.6911 11.0213 11.6232 10.9673 11.594C10.7614 11.4824 10.5565 11.2733 10.4412 11.0524C10.3031 10.7773 10.2559 10.469 10.329 10.1505C10.3289 10.1505 10.3291 10.1506 10.329 10.1505C10.4132 9.74282 10.8511 9.36359 11.1854 9.01282C11.1847 9.01259 11.1841 9.01234 11.1834 9.0121L12.4359 7.73367C12.9351 7.22421 13.7562 7.22 14.2605 7.72435C14.77 8.2235 14.7784 9.04872 14.2792 9.55817L13.5205 10.3384C13.4854 10.3745 13.474 10.4272 13.4905 10.4748C13.6652 10.9813 13.7081 11.6956 13.5911 12.2353C13.5878 12.2504 13.6064 12.2603 13.6172 12.2492L15.232 10.6012C16.2635 9.54834 16.2548 7.83421 15.2125 6.79198C14.1489 5.72836 12.4174 5.73721 11.3647 6.81162L9.71017 8.50025C9.70798 8.50255 9.70591 8.5049 9.7037 8.50717Z"
        fill="currentColor"
      />
      <path
        d="M12.7225 12.8776C12.7225 12.8777 12.7225 12.8776 12.7225 12.8776C12.7235 12.8772 12.7244 12.877 12.7255 12.8765C13.055 12.2739 13.1199 11.5828 12.9654 10.9091L12.9647 10.9098C12.8181 10.3098 12.4149 9.71357 11.8684 9.34671C11.8214 9.31515 11.7463 9.31881 11.7023 9.35441C11.4255 9.57824 11.1545 9.86526 10.9757 10.2315C10.9477 10.289 10.9687 10.3581 11.024 10.3902C11.2315 10.5107 11.4189 10.6871 11.5444 10.9212C11.6421 11.0866 11.7387 11.4004 11.6763 11.7376C11.6762 11.7376 11.6763 11.7376 11.6763 11.7376C11.6181 12.1847 11.1664 12.5948 10.8075 12.9643C10.5343 13.2437 9.83996 13.9519 9.56187 14.236C9.06272 14.7455 8.2375 14.7539 7.72806 14.2547C7.21861 13.7556 7.21019 12.9304 7.70934 12.4209L8.47028 11.6384C8.50477 11.6029 8.5165 11.5514 8.50116 11.5043C8.3322 10.9859 8.28591 10.2877 8.39255 9.74867C8.39552 9.73364 8.37704 9.72405 8.36631 9.73499L6.77585 11.3583C5.73374 12.4219 5.74257 14.1536 6.79551 15.2065C7.85907 16.2486 9.58186 16.231 10.6239 15.1674C10.9859 14.7625 12.5356 13.3277 12.7225 12.8776Z"
        fill="currentColor"
      />
    </svg>
  );
}
