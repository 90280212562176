import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconPlay({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 20 20"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path fill="currentColor" d="M2 20V0l18 10L2 20Z" />
    </svg>
  );
}
