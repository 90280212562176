import type { IconProps as IconComponentProps } from "design-system/types/types";
import { Icons } from "./icons";

export type IconName = keyof typeof Icons;

interface IconProps extends IconComponentProps {
  name: keyof typeof Icons;
}

export function Icon({ name, ...props }: IconProps) {
  const IconComponent = Icons[name];

  if (IconComponent) return <IconComponent {...props} />;

  return null;
}
