import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconYoutube({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15,22.4725275 L18.6666667,22.4322344 C21.2454212,22.3516484 22.8840051,22.2173384 23.5824176,22.029304 C24.0659341,21.8949941 24.4822953,21.6532359 24.8315018,21.3040293 C25.1807084,20.9548227 25.4224666,20.5518923 25.5567766,20.0952381 C25.7448109,19.3699634 25.8791209,18.2551891 25.959707,16.7509158 L25.959707,16.7509158 L26,14.7362637 L25.959707,12.7216117 C25.8791209,11.2442 25.7448109,10.1428571 25.5567766,9.41758242 C25.4224666,8.93406593 25.1807084,8.51770472 24.8315018,8.16849817 C24.4822953,7.81929161 24.0659341,7.57753337 23.5824176,7.44322344 C22.8840051,7.25518905 21.2454212,7.12087912 18.6666667,7.04029304 L18.6666667,7.04029304 L15,7 L11.3333333,7.04029304 C8.75457875,7.12087912 7.11599491,7.25518905 6.41758242,7.44322344 C5.93406593,7.57753337 5.51770472,7.81929161 5.16849817,8.16849817 C4.81929161,8.51770472 4.57753337,8.93406593 4.44322344,9.41758242 C4.25518905,10.1428571 4.12087912,11.2442 4.04029304,12.7216117 C4.01343081,13.4737487 4,14.1452989 4,14.7362637 L4,14.7362637 L4.04029304,16.7509158 C4.12087912,18.2551891 4.25518905,19.3699634 4.44322344,20.0952381 C4.57753337,20.5518923 4.81929161,20.9548227 5.16849817,21.3040293 C5.51770472,21.6532359 5.93406593,21.8949941 6.41758242,22.029304 C7.11599491,22.2173384 8.75457875,22.3516484 11.3333333,22.4322344 L11.3333333,22.4322344 L15,22.4725275 Z M12.7435897,18.040293 L12.7435897,11.4725275 L18.5054945,14.7362637 L12.7435897,18.040293 Z"
      />
    </svg>
  );
}
