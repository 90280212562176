import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconLinkedIn({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        d="M23.6375751,22.75 L20.0622266,22.75 L20.0622266,17.1475477 C20.0622266,15.8115705 20.0350644,14.0923048 18.1991019,14.0923048 C16.3349711,14.0923048 16.0502712,15.5459847 16.0502712,17.048959 L16.0502712,22.75 L12.4749227,22.75 L12.4749227,11.2292092 L15.9094302,11.2292092 L15.9094302,12.7995859 L15.9557065,12.7995859 C16.4355718,11.8941797 17.6025398,10.9384732 19.3459497,10.9384732 C22.9685805,10.9384732 23.6385811,13.3227095 23.6385811,16.4262407 L23.6385811,22.75 L23.6375751,22.75 Z M8.4368111,9.65279641 C7.28593923,9.65279641 6.36141891,8.72123404 6.36141891,7.5753922 C6.36141891,6.43055637 7.28694524,5.5 8.4368111,5.5 C9.58365895,5.5 10.5132093,6.43055637 10.5132093,7.5753922 C10.5132093,8.72123404 9.58265294,9.65279641 8.4368111,9.65279641 Z M10.2295154,22.75 L6.64410684,22.75 L6.64410684,11.2292092 L10.2295154,11.2292092 L10.2295154,22.75 Z"
      />
    </svg>
  );
}
