import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconComment({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M4.0375 11.0152H12.9625C13.1467 11.0152 13.299 10.955 13.4194 10.8346C13.5398 10.7141 13.6 10.5619 13.6 10.3777C13.6 10.1935 13.5398 10.0412 13.4194 9.92081C13.299 9.80039 13.1467 9.74019 12.9625 9.74019H4.0375C3.85333 9.74019 3.70104 9.80039 3.58063 9.92081C3.46021 10.0412 3.4 10.1935 3.4 10.3777C3.4 10.5619 3.46021 10.7141 3.58063 10.8346C3.70104 10.955 3.85333 11.0152 4.0375 11.0152ZM4.0375 8.25269H12.9625C13.1467 8.25269 13.299 8.19248 13.4194 8.07206C13.5398 7.95164 13.6 7.79935 13.6 7.61519C13.6 7.43102 13.5398 7.27873 13.4194 7.15831C13.299 7.03789 13.1467 6.97769 12.9625 6.97769H4.0375C3.85333 6.97769 3.70104 7.03789 3.58063 7.15831C3.46021 7.27873 3.4 7.43102 3.4 7.61519C3.4 7.79935 3.46021 7.95164 3.58063 8.07206C3.70104 8.19248 3.85333 8.25269 4.0375 8.25269ZM4.0375 5.49019H12.9625C13.1467 5.49019 13.299 5.42998 13.4194 5.30956C13.5398 5.18914 13.6 5.03685 13.6 4.85269C13.6 4.66852 13.5398 4.51623 13.4194 4.39581C13.299 4.27539 13.1467 4.21519 12.9625 4.21519H4.0375C3.85333 4.21519 3.70104 4.27539 3.58063 4.39581C3.46021 4.51623 3.4 4.66852 3.4 4.85269C3.4 5.03685 3.46021 5.18914 3.58063 5.30956C3.70104 5.42998 3.85333 5.49019 4.0375 5.49019ZM15.9163 16.7314L13.6 14.4152H1.275C0.949167 14.4152 0.655208 14.2841 0.393125 14.0221C0.131042 13.76 0 13.466 0 13.1402V2.09019C0 1.76435 0.131042 1.47039 0.393125 1.20831C0.655208 0.946227 0.949167 0.815186 1.275 0.815186H15.725C16.065 0.815186 16.3625 0.946227 16.6175 1.20831C16.8725 1.47039 17 1.76435 17 2.09019V16.2852C17 16.5685 16.869 16.7633 16.6069 16.8696C16.3448 16.9758 16.1146 16.9298 15.9163 16.7314ZM1.275 2.09019V13.1402H14.1313L15.725 14.7339V2.09019H1.275ZM1.275 2.09019V14.7339V13.1402V2.09019Z"
        fill="currentColor"
        transform="translate(6.5, 6.945)"
      />
    </svg>
  );
}
