import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconQuickLink({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      className={className}
      fill="none"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M12.9472 11.0382C11.0659 12.9847 11.4764 16.215 13.6315 17.646C13.7025 17.6932 13.7969 17.6838 13.8579 17.6242C14.3116 17.1809 14.6954 16.7515 15.0315 16.2054C15.0829 16.1219 15.0509 16.0135 14.9647 15.9667C14.636 15.7887 14.3089 15.4547 14.1248 15.102C13.9042 14.6628 13.8289 14.1706 13.9456 13.6621C13.9455 13.6621 13.9458 13.6621 13.9456 13.6621C14.08 13.0111 14.7793 12.4056 15.313 11.8456C15.3119 11.8452 15.3108 11.8448 15.3097 11.8444L17.3096 9.80324C18.1066 8.9898 19.4176 8.98309 20.2228 9.78836C21.0362 10.5853 21.0497 11.9029 20.2527 12.7163L19.0413 13.962C18.9853 14.0196 18.9671 14.1038 18.9933 14.1798C19.2722 14.9886 19.3408 16.1291 19.1539 16.9907C19.1487 17.0148 19.1785 17.0306 19.1957 17.013L21.7739 14.3816C23.4209 12.7006 23.4069 9.96375 21.7428 8.29969C20.0446 6.60147 17.28 6.6156 15.5992 8.33105L12.9576 11.0272C12.9541 11.0309 12.9508 11.0346 12.9472 11.0382Z"
        fill="currentColor"
      />
      <path
        d="M17.7666 18.0167C17.7667 18.0166 17.7666 18.0168 17.7666 18.0167C17.7683 18.016 17.7697 18.0156 17.7713 18.0149C18.2975 17.0528 18.4011 15.9493 18.1545 14.8736L18.1534 14.8748C17.9192 13.9167 17.2755 12.9648 16.403 12.3791C16.3279 12.3287 16.208 12.3345 16.1377 12.3914C15.6957 12.7487 15.2631 13.207 14.9776 13.7918C14.9328 13.8837 14.9664 13.9939 15.0547 14.0452C15.386 14.2376 15.6852 14.5192 15.8856 14.893C16.0417 15.1571 16.1958 15.658 16.0962 16.1964C16.0962 16.1964 16.0961 16.1964 16.0962 16.1964C16.0032 16.9103 15.2821 17.5651 14.7091 18.1551C14.2728 18.6012 13.1642 19.7319 12.7202 20.1855C11.9233 20.9989 10.6057 21.0124 9.79228 20.2154C8.97888 19.4184 8.96543 18.1009 9.7624 17.2875L10.9774 16.038C11.0324 15.9814 11.0511 15.8991 11.0266 15.824C10.7569 14.9963 10.683 13.8815 10.8532 13.0209C10.858 12.9969 10.8285 12.9816 10.8113 12.999L8.27195 15.5908C6.60808 17.2891 6.62218 20.054 8.30334 21.7351C10.0015 23.3989 12.7521 23.3708 14.416 21.6727C14.994 21.0261 17.4682 18.7353 17.7666 18.0167Z"
        fill="currentColor"
      />
    </svg>
  );
}
