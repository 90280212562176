import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconLink({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 10 10"
      className={className}
      fill="none"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M3.7037 2.53012C2.5254 3.74924 2.78253 5.77242 4.13226 6.66866C4.17673 6.6982 4.2359 6.69234 4.2741 6.65502C4.55825 6.37738 4.79863 6.10845 5.00912 5.76642C5.04132 5.71409 5.02129 5.64619 4.96726 5.61692C4.76139 5.50538 4.55653 5.29624 4.4412 5.07532C4.30306 4.80024 4.25589 4.49196 4.32902 4.17349C4.32895 4.17349 4.3291 4.17351 4.32902 4.17349C4.41318 3.76577 4.85113 3.38654 5.18544 3.03577C5.18474 3.03554 5.18406 3.03528 5.18335 3.03505L6.43593 1.75662C6.9351 1.24715 7.75617 1.24295 8.26052 1.7473C8.76997 2.24645 8.77839 3.07167 8.27924 3.58112L7.52054 4.36131C7.48543 4.39742 7.47403 4.4501 7.49047 4.49772C7.66516 5.0043 7.70812 5.71858 7.59106 6.25824C7.58778 6.27334 7.60642 6.28323 7.61722 6.27219L9.23198 4.62411C10.2635 3.57129 10.2548 1.85716 9.21253 0.814929C8.14891 -0.248695 6.41737 -0.239844 5.36467 0.834566L3.71017 2.5232C3.70798 2.5255 3.70591 2.52785 3.7037 2.53012Z"
        fill="currentColor"
      />
      <path
        d="M6.72251 6.90083C6.72249 6.90089 6.72253 6.90077 6.72251 6.90083C6.72355 6.9004 6.72443 6.90017 6.72546 6.89972C7.05501 6.29712 7.11988 5.60601 6.96544 4.93229L6.96473 4.93301C6.81808 4.33295 6.41493 3.73677 5.86844 3.3699C5.82142 3.33835 5.74633 3.342 5.70231 3.3776C5.42548 3.60143 5.15452 3.88846 4.97573 4.25474C4.94765 4.31224 4.96868 4.38132 5.02401 4.41344C5.23151 4.5339 5.4189 4.71027 5.54437 4.94442C5.64214 5.10983 5.73869 5.42354 5.67628 5.76077C5.67624 5.76077 5.67632 5.76077 5.67628 5.76077C5.61805 6.20786 5.16642 6.61797 4.80751 6.98751C4.5343 7.26689 3.83996 7.97508 3.56187 8.2592C3.06272 8.76864 2.2375 8.77707 1.72806 8.27792C1.21861 7.77877 1.21019 6.95355 1.70934 6.4441L2.47028 5.66156C2.50477 5.6261 2.5165 5.57455 2.50116 5.52752C2.3322 5.0091 2.28591 4.31091 2.39255 3.77186C2.39552 3.75684 2.37704 3.74724 2.36631 3.75818L0.77585 5.38147C-0.26626 6.4451 -0.257428 8.17682 0.795507 9.22973C1.85907 10.2718 3.58186 10.2542 4.62393 9.19063C4.98595 8.78568 6.53563 7.35088 6.72251 6.90083Z"
        fill="currentColor"
      />
    </svg>
  );
}
