import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconCheckLarge({
  className = "hbs-icon",
  ...props
}: IconProps) {
  return (
    <svg
      viewBox="0 0 11 9"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M11 2.48705L3.67291 9L0 4.55669L1.43388 3.282L3.87768 6.2384L9.77091 1L11 2.48705Z"
        fill="currentColor"
      />
    </svg>
  );
}
