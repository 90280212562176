import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconPause({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 20 20"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path fill="currentColor" d="M2 0h6v20H2zM12 0h6v20h-6z" />
    </svg>
  );
}
