import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconEllipsis({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 10 2"
      className={className}
      aria-label="ellipses"
      {...props}
    >
      <path
        d="M0.929935 1.93824C1.41994 1.93824 1.82594 1.54624 1.82594 1.04224C1.82594 0.53824 1.41994 0.14624 0.929935 0.14624C0.439936 0.14624 0.0339355 0.53824 0.0339355 1.04224C0.0339355 1.54624 0.439936 1.93824 0.929935 1.93824ZM4.59794 1.93824C5.08794 1.93824 5.49394 1.54624 5.49394 1.04224C5.49394 0.53824 5.08794 0.14624 4.59794 0.14624C4.10794 0.14624 3.70194 0.53824 3.70194 1.04224C3.70194 1.54624 4.10794 1.93824 4.59794 1.93824ZM8.26594 1.93824C8.75594 1.93824 9.16194 1.54624 9.16194 1.04224C9.16194 0.53824 8.75594 0.14624 8.26594 0.14624C7.77594 0.14624 7.36994 0.53824 7.36994 1.04224C7.36994 1.54624 7.77594 1.93824 8.26594 1.93824Z"
        fill="currentColor"
      />
    </svg>
  );
}
