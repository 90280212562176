import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconNavArrow({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 21 20"
      fill="currentColor"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path d="M11 5L16 10M16 10L11 15M16 10H5" strokeWidth="1.25" />
    </svg>
  );
}
