import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconPrint({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M12.2825 4.89519V2.09019H4.7175V4.89519H3.4425V1.45269C3.4425 1.26852 3.50271 1.11623 3.62312 0.995811C3.74354 0.875394 3.89583 0.815186 4.08 0.815186H12.92C13.1042 0.815186 13.2565 0.875394 13.3769 0.995811C13.4973 1.11623 13.5575 1.26852 13.5575 1.45269V4.89519H12.2825ZM1.275 6.17019C1.275 6.17019 1.36708 6.17019 1.55125 6.17019C1.73542 6.17019 1.96917 6.17019 2.2525 6.17019H14.7475C15.0308 6.17019 15.2646 6.17019 15.4487 6.17019C15.6329 6.17019 15.725 6.17019 15.725 6.17019H13.5575H3.4425H1.275ZM14.0038 8.18894C14.1738 8.18894 14.3225 8.12519 14.45 7.99769C14.5775 7.87019 14.6413 7.72144 14.6413 7.55144C14.6413 7.38144 14.5775 7.23269 14.45 7.10519C14.3225 6.97769 14.1738 6.91394 14.0038 6.91394C13.8338 6.91394 13.685 6.97769 13.5575 7.10519C13.43 7.23269 13.3663 7.38144 13.3663 7.55144C13.3663 7.72144 13.43 7.87019 13.5575 7.99769C13.685 8.12519 13.8338 8.18894 14.0038 8.18894ZM4.7175 14.8402H12.2825V10.7602H4.7175V14.8402ZM4.7175 16.1152C4.36333 16.1152 4.06229 15.9912 3.81438 15.7433C3.56646 15.4954 3.4425 15.1944 3.4425 14.8402V12.3752H0.6375C0.453333 12.3752 0.301042 12.315 0.180625 12.1946C0.0602084 12.0741 0 11.9219 0 11.7377V7.14769C0 6.51019 0.216042 5.97539 0.648125 5.54331C1.08021 5.11123 1.615 4.89519 2.2525 4.89519H14.7475C15.385 4.89519 15.9198 5.11123 16.3519 5.54331C16.784 5.97539 17 6.51019 17 7.14769V11.7377C17 11.9219 16.9398 12.0741 16.8194 12.1946C16.699 12.315 16.5467 12.3752 16.3625 12.3752H13.5575V14.8402C13.5575 15.1944 13.4335 15.4954 13.1856 15.7433C12.9377 15.9912 12.6367 16.1152 12.2825 16.1152H4.7175ZM15.725 11.1002V7.14769C15.725 6.86435 15.6329 6.6306 15.4487 6.44644C15.2646 6.26227 15.0308 6.17019 14.7475 6.17019H2.2525C1.96917 6.17019 1.73542 6.26227 1.55125 6.44644C1.36708 6.6306 1.275 6.86435 1.275 7.14769V11.1002H3.4425V9.48519H13.5575V11.1002H15.725Z"
        fill="currentColor"
        transform="translate(6.5, 7.35)"
      />
    </svg>
  );
}
