import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconFacebook({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12.75,12 L10.5,12 L10.5,15 L12.75,15 L12.75,24 L16.5,24 L16.5,15 L19.2315,15 L19.5,12 L16.5,12 L16.5,10.74975 C16.5,10.0335 16.644,9.75 17.33625,9.75 L19.5,9.75 L19.5,6 L16.644,6 C13.947,6 12.75,7.18725 12.75,9.46125 L12.75,12 Z"
      />
    </svg>
  );
}
