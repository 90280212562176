import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconMail({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M1.275 14.4152C0.935 14.4152 0.6375 14.2877 0.3825 14.0327C0.1275 13.7777 0 13.4802 0 13.1402V2.09019C0 1.75019 0.1275 1.45269 0.3825 1.19769C0.6375 0.942685 0.935 0.815186 1.275 0.815186H15.725C16.065 0.815186 16.3625 0.942685 16.6175 1.19769C16.8725 1.45269 17 1.75019 17 2.09019V13.1402C17 13.4802 16.8725 13.7777 16.6175 14.0327C16.3625 14.2877 16.065 14.4152 15.725 14.4152H1.275ZM15.725 3.25894L8.84 7.76394C8.78333 7.79227 8.73021 7.81706 8.68062 7.83831C8.63104 7.85956 8.57083 7.87019 8.5 7.87019C8.42917 7.87019 8.36896 7.85956 8.31938 7.83831C8.26979 7.81706 8.21667 7.79227 8.16 7.76394L1.275 3.25894V13.1402H15.725V3.25894ZM8.5 6.72269L15.64 2.09019H1.38125L8.5 6.72269ZM1.275 3.25894V3.40769C1.275 3.36519 1.275 3.30852 1.275 3.23769C1.275 3.16685 1.275 3.08894 1.275 3.00394C1.275 2.81977 1.275 2.67456 1.275 2.56831C1.275 2.46206 1.275 2.4656 1.275 2.57894V2.09019V2.57894C1.275 2.4656 1.275 2.45852 1.275 2.55769C1.275 2.65685 1.275 2.79852 1.275 2.98269C1.275 3.06769 1.275 3.14914 1.275 3.22706C1.275 3.30498 1.275 3.36519 1.275 3.40769V3.25894V13.1402V3.25894Z"
        fill="currentColor"
        transform="translate(6.5, 8.2)"
      />
    </svg>
  );
}
