import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconQuestionMark({
  className = "hbs-icon",
  ...props
}: IconProps) {
  return (
    <svg
      viewBox="0 0 5 7"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M2.29252 3.88983C2.29905 4.21133 2.46502 4.36878 2.79044 4.36218C2.94335 4.35908 3.06208 4.3145 3.14663 4.22846C3.23118 4.14241 3.27222 4.03862 3.26975 3.91707L3.26832 3.8465C3.26482 3.67399 3.31278 3.52494 3.41222 3.39937C3.51165 3.2738 3.66584 3.14025 3.87477 2.99873C4.16493 2.80065 4.40294 2.59381 4.58881 2.37823C4.77467 2.16265 4.8639 1.87255 4.8565 1.50792C4.84966 1.17073 4.75373 0.889288 4.56873 0.663583C4.38372 0.437878 4.14397 0.269177 3.84949 0.15748C3.555 0.0457838 3.23525 -0.00656418 2.89022 0.000436184C2.36484 0.0110958 1.94056 0.123647 1.6174 0.33809C1.29423 0.552533 1.09238 0.801776 1.01185 1.08582C0.997124 1.13319 0.985376 1.18246 0.976608 1.23363C0.96784 1.28479 0.963973 1.33586 0.965007 1.38683C0.967712 1.52014 1.01489 1.62214 1.10654 1.69285C1.19819 1.76355 1.29498 1.79787 1.39692 1.7958C1.49886 1.79374 1.58466 1.76944 1.65432 1.72292C1.72398 1.6764 1.78554 1.6173 1.839 1.54561L1.94187 1.39643C2.01398 1.2773 2.09625 1.17561 2.18868 1.09136C2.28111 1.00712 2.38373 0.942277 2.49656 0.896842C2.60939 0.851406 2.73245 0.827336 2.86576 0.824631C3.14021 0.819063 3.36219 0.885162 3.53168 1.02293C3.70118 1.1607 3.78827 1.34524 3.79297 1.57657C3.79718 1.78437 3.73593 1.95525 3.60921 2.08922C3.48249 2.22319 3.28769 2.38502 3.02482 2.57471C2.81228 2.73199 2.63531 2.90228 2.4939 3.08558C2.35249 3.26888 2.28485 3.51148 2.29097 3.81338L2.29252 3.88983Z"
        fill="currentColor"
      />
      <path
        d="M2.34995 5.99498C2.48376 6.11386 2.63692 6.17155 2.80943 6.16805C2.98587 6.16447 3.13852 6.10058 3.2674 5.97637C3.39628 5.85216 3.45893 5.70184 3.45535 5.5254C3.45177 5.34897 3.38404 5.20031 3.25215 5.07943C3.12026 4.95856 2.96413 4.89995 2.78378 4.9036C2.60734 4.90718 2.45571 4.97302 2.32887 5.10111C2.20203 5.2292 2.14036 5.3795 2.14386 5.55201C2.14744 5.72845 2.21613 5.8761 2.34995 5.99498Z"
        fill="currentColor"
      />
    </svg>
  );
}
