import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconBell({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 14 17"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M7.00175 16.332C7.91842 16.332 8.66842 15.582 8.66842 14.6654H5.33509C5.33509 15.582 6.07675 16.332 7.00175 16.332ZM12.0018 11.332V7.16536C12.0018 4.60703 10.6351 2.46536 8.25175 1.8987V1.33203C8.25175 0.640365 7.69342 0.0820312 7.00175 0.0820312C6.31009 0.0820312 5.75175 0.640365 5.75175 1.33203V1.8987C3.36009 2.46536 2.00175 4.5987 2.00175 7.16536V11.332L0.926752 12.407C0.401752 12.932 0.768418 13.832 1.51009 13.832H12.4851C13.2268 13.832 13.6018 12.932 13.0768 12.407L12.0018 11.332Z"
        fill="currentColor"
      />
    </svg>
  );
}
