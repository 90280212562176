import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconMinus({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      className={className}
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="none"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path d="M0 1H12" strokeWidth="2" />
    </svg>
  );
}
