import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconCaret({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 10 6"
      fill="currentColor"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path d="M1 0.5L5 4.5L9 0.5" />
    </svg>
  );
}
