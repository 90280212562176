import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconCaretHeavy({
  className = "hbs-icon",
  ...props
}: IconProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 12 8"
      fill="currentColor"
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path d="M1 1L6 6L11 1" strokeWidth="2" />
    </svg>
  );
}
