import { IconProps } from "design-system/types/types";

/**
 *
 * - **`id: P-015-000-00`**
 */
export function IconClose({ className = "hbs-icon", ...props }: IconProps) {
  return (
    <svg
      viewBox="0 0 12 12"
      className={className}
      focusable="false"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6 7.13149L9.93426 11.0657L11.0657 9.93426L7.13149 6L11.0657 2.06574L9.93426 0.934256L6 4.86851L2.06574 0.934256L0.934256 2.06574L4.86851 6L0.934256 9.93426L2.06574 11.0657L6 7.13149Z"
      />
    </svg>
  );
}
